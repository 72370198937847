<template>
<div>
  <div
    v-if="editable && editor"
    class="menubar text-left md:flex place-items-center"
  >
    <div class="flex">
      <div
        class="select flex justify-end place-items-center"
        @click="toogleShowOptions()"
      >
        <div class="selected">{{ selected_heading }}</div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 14L8 10H16L12 14Z" fill="black" />
        </svg>
      </div>
      <div class="options absolute" v-show="showOptions">
        <div
          class="option"
          @click="
            selected_heading = 'Paragraph';
            editor.chain().focus().setParagraph().run();
            toogleShowOptions();
          "
          :class="{ 'is-active': editor.isActive('paragraph') }"
        >
          Paragraph
        </div>
        <div
          class="option"
          @click="
            selected_heading = 'Heading 1';
            editor.chain().focus().toggleHeading({ level: 1 }).run();
            toogleShowOptions();
          "
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >
          Heading 1
        </div>
        <div
          class="option"
          @click="
            selected_heading = 'Heading 2';
            editor.chain().focus().toggleHeading({ level: 2 }).run();
            toogleShowOptions();
          "
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        >
          Heading 2
        </div>
        <div
          class="option"
          @click="
            selected_heading = 'Heading 3';
            editor.chain().focus().toggleHeading({ level: 3 }).run();
            toogleShowOptions();
          "
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        >
          Heading 3
        </div>
        <!-- <div
        class="option"
        @click="
          selected_heading = 'Heading 4';
          editor.chain().focus().toggleHeading({ level: 4 }).run();
          toogleShowOptions();
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        Heading 4
      </div>
      <div
        class="option"
        @click="
          selected_heading = 'Heading 5';
          editor.chain().focus().toggleHeading({ level: 5 }).run();
          toogleShowOptions();
        "
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        Heading 5
      </div> -->
      </div>
      <svg
        width="2"
        height="21"
        viewBox="0 0 2 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1"
          y1="20.4004"
          x2="1"
          y2="0.400391"
          stroke="#E2E5E8"
          stroke-width="2"
        />
      </svg>

      <div
        class="text-modifier"
        @click="editor.chain().focus().toggleBold().run()"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 11H12.5C13.163 11 13.7989 10.7366 14.2678 10.2678C14.7366 9.79893 15 9.16304 15 8.5C15 7.83696 14.7366 7.20107 14.2678 6.73223C13.7989 6.26339 13.163 6 12.5 6H8V11ZM18 15.5C18 16.0909 17.8836 16.6761 17.6575 17.2221C17.4313 17.768 17.0998 18.2641 16.682 18.682C16.2641 19.0998 15.768 19.4313 15.2221 19.6575C14.6761 19.8836 14.0909 20 13.5 20H6V4H12.5C13.381 4.00004 14.2425 4.25865 14.9779 4.74378C15.7132 5.2289 16.29 5.9192 16.6367 6.72907C16.9834 7.53894 17.0847 8.43276 16.9282 9.29969C16.7716 10.1666 16.3641 10.9685 15.756 11.606C16.4386 12.0013 17.0053 12.5692 17.3992 13.2526C17.7931 13.9361 18.0003 14.7112 18 15.5ZM8 13V18H13.5C14.163 18 14.7989 17.7366 15.2678 17.2678C15.7366 16.7989 16 16.163 16 15.5C16 14.837 15.7366 14.2011 15.2678 13.7322C14.7989 13.2634 14.163 13 13.5 13H8Z"
          />
        </svg>
      </div>
      <div
        class="text-modifier"
        @click="editor.chain().focus().toggleItalic().run()"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 20H7V18H9.927L12.043 6H9V4H17V6H14.073L11.957 18H15V20Z"
          />
        </svg>
      </div>
      <div
        class="text-modifier"
        @click="editor.chain().focus().toggleUnderline().run()"
        :class="{ 'is-active': editor.isActive('underline') }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 3V12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12V3H18V12C18 13.5913 17.3679 15.1174 16.2426 16.2426C15.1174 17.3679 13.5913 18 12 18C10.4087 18 8.88258 17.3679 7.75736 16.2426C6.63214 15.1174 6 13.5913 6 12V3H8ZM4 20H20V22H4V20Z"
          />
        </svg>
      </div>
    </div>
    <svg
      class="text-modifier invisible lg:visible"
      width="2"
      height="21"
      viewBox="0 0 2 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="20.4004"
        x2="1"
        y2="0.400391"
        stroke="#E2E5E8"
        stroke-width="2"
      />
    </svg>
    <div class="flex">
      <div class="font-size flex text-modifier">
        <div class="bordered">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.16602 11.166H15.836V12.8327H6.16602V11.166Z"
              fill="black"
            />
          </svg>
        </div>
        <div class="bordered wide">11</div>
        <div class="bordered">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7143 10.7143V6H12.2857V10.7143H17V12.2857H12.2857V17H10.7143V12.2857H6V10.7143H10.7143Z"
              fill="black"
            />
          </svg>
        </div>
      </div>
      <svg
        class="text-modifier"
        width="2"
        height="21"
        viewBox="0 0 2 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1"
          y1="20.4004"
          x2="1"
          y2="0.400391"
          stroke="#E2E5E8"
          stroke-width="2"
        />
      </svg>
      <div
        class="text-modifier"
        @click="editor.chain().focus().setTextAlign('left').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 4H21V6H3V4ZM3 19H17V21H3V19ZM3 14H21V16H3V14ZM3 9H17V11H3V9Z"
          />
        </svg>
      </div>
      <div
        class="text-modifier"
        @click="editor.chain().focus().setTextAlign('center').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 4H21V6H3V4ZM5 19H19V21H5V19ZM3 14H21V16H3V14ZM5 9H19V11H5V9Z"
          />
        </svg>
      </div>
      <div
        class="text-modifier"
        @click="editor.chain().focus().setTextAlign('right').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 2H21V4H3V2ZM7 17H21V19H7V17ZM3 12H21V14H3V12ZM7 7H21V9H7V7Z"
          />
        </svg>
      </div>
      <div
        class="text-modifier"
        @click="editor.chain().focus().setTextAlign('justify').run()"
        :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 4H21V6H3V4ZM3 19H21V21H3V19ZM3 14H21V16H3V14ZM3 9H21V11H3V9Z"
          />
        </svg>
      </div>
    </div>
    <svg
      class="text-modifier invisible lg:visible"
      width="2"
      height="21"
      viewBox="0 0 2 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="1"
        y1="20.4004"
        x2="1"
        y2="0.400391"
        stroke="#E2E5E8"
        stroke-width="2"
      />
    </svg>
    <div class="flex">
      <div
        class="text-modifier"
        @click="editor.chain().focus().toggleOrderedList().run()"
        :class="{ 'is-active': editor.isActive('orderedList') }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 4H21V6H8V4ZM5 3V6H6V7H3V6H4V4H3V3H5ZM3 14V11.5H5V11H3V10H6V12.5H4V13H6V14H3ZM5 19.5H3V18.5H5V18H3V17H6V21H3V20H5V19.5ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"
          />
        </svg>
      </div>
      <div
        class="text-modifier"
        @click="editor.chain().focus().toggleBulletList().run()"
        :class="{ 'is-active': editor.isActive('bulletList') }"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 4H21V6H8V4ZM4.5 6.5C4.10218 6.5 3.72064 6.34196 3.43934 6.06066C3.15804 5.77936 3 5.39782 3 5C3 4.60218 3.15804 4.22064 3.43934 3.93934C3.72064 3.65804 4.10218 3.5 4.5 3.5C4.89782 3.5 5.27936 3.65804 5.56066 3.93934C5.84196 4.22064 6 4.60218 6 5C6 5.39782 5.84196 5.77936 5.56066 6.06066C5.27936 6.34196 4.89782 6.5 4.5 6.5ZM4.5 13.5C4.10218 13.5 3.72064 13.342 3.43934 13.0607C3.15804 12.7794 3 12.3978 3 12C3 11.6022 3.15804 11.2206 3.43934 10.9393C3.72064 10.658 4.10218 10.5 4.5 10.5C4.89782 10.5 5.27936 10.658 5.56066 10.9393C5.84196 11.2206 6 11.6022 6 12C6 12.3978 5.84196 12.7794 5.56066 13.0607C5.27936 13.342 4.89782 13.5 4.5 13.5ZM4.5 20.4C4.10218 20.4 3.72064 20.242 3.43934 19.9607C3.15804 19.6794 3 19.2978 3 18.9C3 18.5022 3.15804 18.1206 3.43934 17.8393C3.72064 17.558 4.10218 17.4 4.5 17.4C4.89782 17.4 5.27936 17.558 5.56066 17.8393C5.84196 18.1206 6 18.5022 6 18.9C6 19.2978 5.84196 19.6794 5.56066 19.9607C5.27936 20.242 4.89782 20.4 4.5 20.4ZM8 11H21V13H8V11ZM8 18H21V20H8V18Z"
          />
        </svg>
      </div>
      <svg
        class="text-modifier"
        width="2"
        height="21"
        viewBox="0 0 2 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1"
          y1="20.4004"
          x2="1"
          y2="0.400391"
          stroke="#E2E5E8"
          stroke-width="2"
        />
      </svg>
      <div class="text-modifier" @click="addImage">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 11.1L7 9.1L12.5 14.6L16 11.1L19 14.1V5H5V11.1ZM4 3H20C20.2652 3 20.5196 3.10536 20.7071 3.29289C20.8946 3.48043 21 3.73478 21 4V20C21 20.2652 20.8946 20.5196 20.7071 20.7071C20.5196 20.8946 20.2652 21 20 21H4C3.73478 21 3.48043 20.8946 3.29289 20.7071C3.10536 20.5196 3 20.2652 3 20V4C3 3.73478 3.10536 3.48043 3.29289 3.29289C3.48043 3.10536 3.73478 3 4 3ZM15.5 10C15.1022 10 14.7206 9.84196 14.4393 9.56066C14.158 9.27936 14 8.89782 14 8.5C14 8.10218 14.158 7.72064 14.4393 7.43934C14.7206 7.15804 15.1022 7 15.5 7C15.8978 7 16.2794 7.15804 16.5607 7.43934C16.842 7.72064 17 8.10218 17 8.5C17 8.89782 16.842 9.27936 16.5607 9.56066C16.2794 9.84196 15.8978 10 15.5 10Z"
          />
        </svg>
      </div>
    </div>
  </div>
  <BubbleMenu v-if="editable && editor" class="bubble-menu" :editor="editor">
    <button
      @click="editor.chain().focus().toggleBold().run()"
      :class="{ 'is-active': editor.isActive('bold') }"
    >
      Bold
    </button>
    <button
      @click="editor.chain().focus().toggleItalic().run()"
      :class="{ 'is-active': editor.isActive('italic') }"
    >
      Italic
    </button>
    <button
      @click="editor.chain().focus().toggleStrike().run()"
      :class="{ 'is-active': editor.isActive('strike') }"
    >
      Strike
    </button>
  </BubbleMenu>
  <div class="content-container custom-scroll">
    <EditorContent class="content mx-auto" :editor="editor" />
  </div>
  </div>
</template>

<script>
// Import the editor
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import { defaultExtensions } from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";

import {emit} from "@/utils/event_bus";
export default {
  name: "Editor",
  components: {
    EditorContent,
    BubbleMenu,
  },
  emits: ["newContent", "newHTML"],
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    default_content: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      editor: null,
      showOptions: false,
      selected_heading: "Paragraph",
    };
  },
  methods: {
    toogleShowOptions() {
      this.showOptions = !this.showOptions;
    },
    changeHeading() {
      this.selected_;
    },
    addImage() {
      const url = window.prompt("URL");

      if (url) {
        this.editor.chain().focus().setImage({ src: url }).run();
      }
    },
  },
  mounted() {
    const extensions = defaultExtensions();
    // add imported extensions
    extensions.push(Underline);
    extensions.push(TextAlign);
    extensions.push(BulletList);
    extensions.push(OrderedList);
    extensions.push(ListItem);
    extensions.push(Image);
    // const emit = this.$emit;
    const content = this.default_content;
    this.editor = new Editor({
      editable: this.editable,
      content: content || ``,
      extensions: extensions,
      onUpdate() {
        emit("newContent", this.getJSON());
      },
    });
  },
  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
.menubar {
  border-bottom: 1px solid #28a4e2;
  padding: 5px;
  background: white;
  .select {
    width: 150px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    color: #032b3d;
    &:focus {
      outline: none;
    }
    .selected {
      margin-right: 10px;
    }
    svg {
      margin-right: 6px;
    }
  }
  .options {
    background: #ffffff;
    border: 1px solid rgba(207, 207, 207, 0.41);
    padding: 6px;
    width: 151px;
    margin-top: 136px;
    z-index: 9;
  }
  .text-modifier {
    margin-left: 20px;
    svg {
      fill: black;
    }
    &.is-active {
      background: black;
      border-radius: 4px;
      svg {
        fill: #ffffff;
      }
    }
  }

  .font-size {
    .bordered {
      padding: 0 3px;
      border: 1px solid #e2e5e8;
      &.wide {
        padding: 0 10px;
      }
    }
  }
}
.content-container {
  height: 399px;
  overflow-y: auto;
}
.content {
  background: #ffffff;
  box-shadow: 0px 1px 0px #28a4e2;
  border-radius: 3px 3px 0px 0px;
  width: 90%;
  margin: 20px auto;
  // min-height: 91%;
      min-height: 364px;
  padding: 30px 45px;
  font-family: Roboto;

  color: #000000;
}
.ProseMirror {
  &:focus {
    outline: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }
  h1 {
    display: block;
    font-size: 2em !important;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
  }
  h2 {
    display: block;
    font-size: 1.5em !important;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
  }

  h3 {
    display: block;
    font-size: 1.17em !important;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold !important;
  }

  > * + * {
    margin-top: 0.75em;
  }

  ol {
    list-style: decimal;
  }
  ul {
    list-style-type: disc;
  }

  ul,
  ol {
    padding: 0 1rem;
  }
}
.bubble-menu {
  display: flex;
  background-color: #0d0d0d;
  padding: 0.2rem;
  border-radius: 0.5rem;
  transition: visibility 0.1s ease, opacity 0.1s ease;

  button {
    border: none;
    background: none;
    color: #fff;
    font-size: 0.85rem;
    font-weight: 500;
    padding: 0 0.2rem;
    opacity: 0.6;

    &:hover,
    &.is-active {
      opacity: 1;
    }
  }
}
</style>