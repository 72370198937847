<template>
  <div id="editor" v-if="appointment">
    <div class="header md:flex place-items-center">
      <div class="back mt-4 cursor-pointer" @click="$router.go(-1)">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.828 10.9997H20V12.9997H7.828L13.192 18.3637L11.778 19.7777L4 11.9997L11.778 4.22168L13.192 5.63568L7.828 10.9997Z"
            fill="#A9B2BC"
          />
        </svg>
      </div>
      <svg
        class="line invisible md:visible"
        width="3"
        height="33"
        viewBox="0 0 3 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="1.5"
          y1="32.2002"
          x2="1.5"
          y2="0.799559"
          stroke="#E2E5E8"
          stroke-width="2"
        />
      </svg>
      <div class="infos ml-10 md:ml-0">
        <div class="doc-title">{{ appointment.title }}</div>
        <div class="last-edited">
          Last edited {{ edited }}
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="5" cy="5" r="5" fill="#BCBDBE" />
          </svg>

          {{
            appointment.updatedBy == $store.state.auth.user.id
              ? "You"
              : lastEditedBy
          }}
        </div>
      </div>
      <div class="actions ml-10 my-4 md:ml-auto md:my-0 flex mx-auto md:mr-24">
        <div class="status" @click="saveChanges()" :class="{ saved: isSaved }">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.9999 21.0004H6.99987C5.5831 21.0013 4.21172 20.5009 3.12856 19.5876C2.0454 18.6744 1.32035 17.4073 1.08178 16.0108C0.843215 14.6142 1.10653 13.1783 1.82511 11.9573C2.54368 10.7363 3.67115 9.80893 5.00787 9.33945C4.96259 8.39311 5.10989 7.44743 5.44083 6.55968C5.77178 5.67194 6.2795 4.86062 6.93322 4.17487C7.58694 3.48912 8.37307 2.94321 9.24398 2.57021C10.1149 2.19721 11.0525 2.00488 11.9999 2.00488C12.9473 2.00488 13.8849 2.19721 14.7558 2.57021C15.6267 2.94321 16.4128 3.48912 17.0665 4.17487C17.7203 4.86062 18.228 5.67194 18.5589 6.55968C18.8899 7.44743 19.0372 8.39311 18.9919 9.33945C20.3286 9.80893 21.4561 10.7363 22.1746 11.9573C22.8932 13.1783 23.1565 14.6142 22.918 16.0108C22.6794 17.4073 21.9543 18.6744 20.8712 19.5876C19.788 20.5009 18.4166 21.0013 16.9999 21.0004Z"
            />
            <path
              d="M10.2037 15.4361L16.5646 9L17.5438 9.98995L10.2037 17.416L5.7998 12.9605L6.7783 11.9706L10.2037 15.4361Z"
              fill="white"
            />
          </svg>
        </div>
        <div class="download hidden">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00042 20.9813C5.63447 20.8764 4.33658 20.3426 3.29208 19.4561C2.24757 18.5696 1.50986 17.3758 1.18428 16.0451C0.858697 14.7144 0.961891 13.3148 1.47913 12.0462C1.99636 10.7777 2.9012 9.70494 4.06442 8.98125C4.31193 7.05134 5.25422 5.27776 6.71494 3.99241C8.17566 2.70706 10.0547 1.99805 12.0004 1.99805C13.9461 1.99805 15.8252 2.70706 17.2859 3.99241C18.7466 5.27776 19.6889 7.05134 19.9364 8.98125C21.0996 9.70494 22.0045 10.7777 22.5217 12.0462C23.039 13.3148 23.1421 14.7144 22.8166 16.0451C22.491 17.3758 21.7533 18.5696 20.7088 19.4561C19.6643 20.3426 18.3664 20.8764 17.0004 20.9813V21.0003H7.00042V20.9813ZM13.0004 12.0003V8.00025H11.0004V12.0003H8.00042L12.0004 17.0003L16.0004 12.0003H13.0004Z"
              fill="#032B3D"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- <Editor
      @newContent="output"
      :editable="false"
      :default_content="sample"
    /> -->
    <Editor
      :default_content="appointment.appointmentMinutes"
      :editable="
        $store.state.auth.user.roles[0].name == 'SECRETARY' ||
        $store.state.auth.user.roles[0].name == 'VIEWER'
      "
    />
  </div>
</template>

<script>
import Editor from "/src/components/Editor";
import Api from "/src/services/Apis";
import {timeAgo} from '../functions'
import {on} from "@/utils/event_bus";

export default {
  name: "Minutes",
  components: {
    Editor,
  },
  data: () => ({
    json: undefined,
    isSaved: true,
    appointment: undefined,
    lastEdited: undefined,
    lastEditedBy: undefined,
    edited: "",
    sample: {
      type: "doc",
      content: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "Wow, this editor instance exports its content as JSON.",
            },
          ],
        },
      ],
    },
  }),
  methods: {
    updateContent(content) {
      this.json = content;
      if (this.isSaved) this.isSaved = false;
      this.lastEdited = new Date().toISOString();
    },
    async getAppointment() {
      const { data } = await Api.get(`appointment/${this.$route.params.id}`);
      this.appointment = data.data;
      if (this.appointment.appointmentMinutes)
        this.appointment.appointmentMinutes = JSON.parse(
          this.appointment.appointmentMinutes
        );

      this.lastEdited = this.appointment.updatedAt;
      const res = await Api.get(`users/${this.appointment.updatedBy}`);
      this.lastEditedBy =
        res.data.data.firstName + " " + res.data.data.lastName;
    },
    async saveChanges() {
      await Api.create(`appointment/minutes/${this.$route.params.id}`, {
        minutes: JSON.stringify(this.json),
      });

      this.isSaved = true;
      this.$router.push("/appointments");
    },
  },
  created() {
    this.getAppointment();
    on('newContent', (content) => {
      this.updateContent(content)
    })
  },
  watch: {
    lastEdited() {
      this.edited = timeAgo(this.lastEdited);
    },
  },
};
</script>

<style lang="scss">
#editor {
  background: #e5e5e5;
  margin: 18px 18px 0;
  height: calc(100vh - 72px);
  min-height: calc(100vh - 72px);
  .header {
    min-height: 79px;
    background: #ffffff;
    border-bottom: 1px solid #28a4e2;
    border-radius: 3px 3px 0px 0px;
    .back {
      position: relative;
      height: 40px;
      width: 40px;
      min-width: 40px;
      background: #e2e5e8;
      border-radius: 50px;
      display: flex;
      place-items: center;
      justify-content: center;
      margin-left: 37px;
    }
    .line {
      margin: 0 30.5px;
      // position: absolute;
    }
    .infos {
      font-family: Inter;
      font-style: normal;
      text-align: left;
      .doc-title {
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color: #032b3d;
        margin-bottom: 8px;
      }
      .last-edited {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        display: flex;
        place-items: center;
        color: #bcbdbe;
        svg {
          margin: 0 15px;
        }
      }
    }
    .actions {
      .status {
        margin-right: 23px;
        cursor: pointer;
        &.saved {
          svg {
            fill: greenyellow;
          }
        }
      }
    }
  }
}
</style>