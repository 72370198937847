<template>
  <div id="doc-history" class="mx-4 lg:mx-10 mt-11" v-if="document">
    <div class="doc-title">{{ document.title }}</div>
    <div class="infos lg:flex">
      <div class="item flex">
        <div class="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 14V22H4C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14ZM18 21.5L15.061 23.045L15.622 19.773L13.245 17.455L16.531 16.977L18 14L19.47 16.977L22.755 17.455L20.378 19.773L20.938 23.045L18 21.5ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13Z"
              fill="#5C5D63"
            />
          </svg>
        </div>
        <div v-if="timelineitems.length" class="label">Created by:</div>
        <div v-if="timelineitems.length" class="value">{{ `${timelineitems[timelineitems.length - 1].createdByUser.firstName} ${timelineitems[timelineitems.length - 1].createdByUser.lastName}`}}</div>
      </div>
      <div class="item flex">
        <div class="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 16C17.657 16 19 17.343 19 19C19 20.657 17.657 22 16 22C14.343 22 13 20.657 13 19C13 17.343 14.343 16 16 16ZM6 12C8.21 12 10 13.79 10 16C10 18.21 8.21 20 6 20C3.79 20 2 18.21 2 16C2 13.79 3.79 12 6 12ZM14.5 2C17.538 2 20 4.462 20 7.5C20 10.538 17.538 13 14.5 13C11.462 13 9 10.538 9 7.5C9 4.462 11.462 2 14.5 2Z"
              fill="#5C5D63"
            />
          </svg>
        </div>
        <div class="label">Cluster:</div>
        <div class="value">{{ document.institution.cluster.name }}</div>
      </div>
      <div class="item flex">
        <div class="icon">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 19V8H1V6H4V4C4 3.73478 4.10536 3.48043 4.29289 3.29289C4.48043 3.10536 4.73478 3 5 3H19C19.2652 3 19.5196 3.10536 19.7071 3.29289C19.8946 3.48043 20 3.73478 20 4V6H23V8H22V19H23V21H1V19H2ZM13 19V12H11V19H13ZM8 19V12H6V19H8ZM18 19V12H16V19H18ZM6 5V6H18V5H6Z"
              fill="#5C5D63"
            />
          </svg>
        </div>
        <div class="label">Institution:</div>
        <div class="value">{{ document.institution.name }}</div>
      </div>
    </div>
    <div class="actions floating hidden md:block" v-if="timelineitems.length">
      <div
        v-if="
        $store.state.auth.user.roles[0] == 'CLUSTER_USER' ||
        $store.state.auth.user.roles[0] == 'CLUSTER_ADMIN'
        "
        class="update flex place-items-center cursor-pointer"
        @click="$router.push(`/documents/${$route.params.id}/edit`)"
      >
        <svg
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.77688 11.8748H13.125V13.1248H1.875V10.4729L8.0625 4.28543L10.7137 6.93793L5.77625 11.8748H5.77688ZM8.94562 3.40231L10.2719 2.07606C10.3891 1.95889 10.548 1.89307 10.7137 1.89307C10.8795 1.89307 11.0384 1.95889 11.1556 2.07606L12.9237 3.84418C13.0409 3.96139 13.1067 4.12033 13.1067 4.28606C13.1067 4.45179 13.0409 4.61073 12.9237 4.72793L11.5975 6.05356L8.94625 3.40231H8.94562Z"
            fill="white"
          />
        </svg>
        Update document
      </div>
      <div
        @click="showNewComment = true"
        v-show="!showNewComment"
        class="add-comment flex place-items-center cursor-pointer"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.13334 13.6616L1.92383 16.0702V3.33905C1.92383 3.15654 1.99973 2.9815 2.13484 2.85244C2.26994 2.72338 2.45319 2.65088 2.64426 2.65088H15.612C15.8031 2.65088 15.9863 2.72338 16.1214 2.85244C16.2565 2.9815 16.3324 3.15654 16.3324 3.33905V12.9735C16.3324 13.156 16.2565 13.331 16.1214 13.4601C15.9863 13.5891 15.8031 13.6616 15.612 13.6616H5.13334ZM8.4077 7.46808H6.24641V8.84443H8.4077V10.9089H9.84856V8.84443H12.0098V7.46808H9.84856V5.40357H8.4077V7.46808Z"
            fill="#1679A8"
          />
        </svg>
        Add a comment
      </div>
    </div>
    <div class="history md:flex" v-if="elementsAvailble">
      <div class="summary">
        <div class="title flex place-items-center">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12H4C4 16.418 7.582 20 12 20C16.418 20 20 16.418 20 12C20 7.582 16.418 4 12 4C9.25 4 6.824 5.387 5.385 7.5H8V9.5H2V3.5H4V6C5.824 3.57 8.729 2 12 2ZM13 7V11.585L16.243 14.828L14.828 16.243L11 12.413V7H13Z"
              fill="#7C8197"
            />
          </svg>
          Document history
        </div>
        <Search
        class="hidden"
          label="Search by title, comment"
          @input="getValue"
          :isGrey="true"
        />
        <div class="timeline custom-scroll">
          <TimeLineItem
            v-for="(item, i) in timelineitems"
            :key="i"
            :title="item.meetingTitle"
            :id="item.id"
            :subtitle="`Done by ${item.createdByUser.firstName} ${item.createdByUser.lastName}`"
            :date="item.createdAt"
            :user="item.createdByUser"
            :active="item == selected_timeline"
            :file="item.file"
            @click.native="selected_timeline = item"
          />
        </div>
      </div>
      <div class="actions flex mt-10 block md:hidden">
        
        <div
          v-if="
          $store.state.auth.user.roles[0] == 'CLUSTER_USER' ||
          $store.state.auth.user.roles[0] == 'CLUSTER_ADMIN'
          "
          class="update flex place-items-center mr-4 cursor-pointer"
          @click="$router.push(`/documents/${$route.params.id}/edit`)"
        >
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.77688 11.8748H13.125V13.1248H1.875V10.4729L8.0625 4.28543L10.7137 6.93793L5.77625 11.8748H5.77688ZM8.94562 3.40231L10.2719 2.07606C10.3891 1.95889 10.548 1.89307 10.7137 1.89307C10.8795 1.89307 11.0384 1.95889 11.1556 2.07606L12.9237 3.84418C13.0409 3.96139 13.1067 4.12033 13.1067 4.28606C13.1067 4.45179 13.0409 4.61073 12.9237 4.72793L11.5975 6.05356L8.94625 3.40231H8.94562Z"
              fill="white"
            />
          </svg>
          Update document
        </div>
        <div
          @click="showNewComment = true"
          v-show="!showNewComment"
          class="add-comment flex place-items-center cursor-pointer"
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.13334 13.6616L1.92383 16.0702V3.33905C1.92383 3.15654 1.99973 2.9815 2.13484 2.85244C2.26994 2.72338 2.45319 2.65088 2.64426 2.65088H15.612C15.8031 2.65088 15.9863 2.72338 16.1214 2.85244C16.2565 2.9815 16.3324 3.15654 16.3324 3.33905V12.9735C16.3324 13.156 16.2565 13.331 16.1214 13.4601C15.9863 13.5891 15.8031 13.6616 15.612 13.6616H5.13334ZM8.4077 7.46808H6.24641V8.84443H8.4077V10.9089H9.84856V8.84443H12.0098V7.46808H9.84856V5.40357H8.4077V7.46808Z"
              fill="#1679A8"
            />
          </svg>
          Add a comment
        </div>
      </div>
      <div
        v-if="selected_timeline"
        class="detailed w-full custom-scroll md:pl-11 mt-12"
      >
        <div class="flex">
          <div
            class="image"
            v-if="selected_timeline.createdByUser.imageUrl"
            :style="`
            background: url(${selected_timeline.createdByUser.imageUrl});
            background-position: center;
            background-size: cover;`"
          />
          <Avatar
            v-else
            :fullname="
              `${selected_timeline.createdByUser.firstName}` +
              ' ' +
              `${selected_timeline.createdByUser.lastName}`
            "
            :size="42"
            color="#054D6F"
            class="mt-1 sm:mt-2 image"
          ></Avatar>
          <div>
            <div class="date">
              {{ selected_timeline.createdAt | timeAgo }}
            </div>
            <div class="title">{{ selected_timeline.meetingDescription }}</div>
            <div class="total_comments">
              {{ timelinecomments.length }} comments
            </div>
          </div>
        </div>
        <div class="flex h-3/4">
          <div class="borders relative">
            <div
              v-for="i in timelinecomments.length"
              :key="i"
              class="border-div flex justify-center"
            >
              <svg
                v-if="i > 1"
                width="20"
                height="179"
                viewBox="0 0 20 179"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10"
                  cy="169"
                  r="9.5"
                  fill="#F2F6FA"
                  stroke="#EAF1F8"
                />
                <circle cx="9.66602" cy="169.333" r="5" fill="#DEDEED" />
                <line
                  x1="11"
                  x2="11"
                  y2="154"
                  stroke="#E3E7EB"
                  stroke-width="2"
                />
              </svg>

              <svg
                v-else
                width="20"
                height="122"
                viewBox="0 0 20 122"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="10"
                  cy="112"
                  r="9.5"
                  fill="#F2F6FA"
                  stroke="#EAF1F8"
                />
                <circle cx="9.66602" cy="112.333" r="5" fill="#DEDEED" />
                <line
                  x1="10.502"
                  y1="0.996582"
                  x2="10.502"
                  y2="97.0066"
                  stroke="#E3E7EB"
                  stroke-width="2"
                />
              </svg>
            </div>
          </div>
          <div class="messages">
            <NewTimeCommentItem
              v-if="showNewComment"
              @cancel="showNewComment = false"
              @save="AddTimelineComment"
              :users="targettedUsersArray"
              @target="checkTargetedUser"
            />
            <TimeCommentItem
              v-for="(item, i) in timelinecomments"
              :key="i"
              :names="`${item.user.firstName} ${item.user.lastName}`"
              :date_time="item.createdAt"
              :content="item.comment"
              :isLast="i == timelinecomments.length - 1"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="no-history">
      <div>
        <img src="/src/assets/icons/no_history.svg" alt="" />
        <div class="words">There is no timeline for this document</div>
      </div>
    </div>
  </div>
</template>

<script>
import Search from "/src/components/searchInput.vue";
import TimeLineItem from "/src/components/Timeline-item.vue";
import TimeCommentItem from "/src/components/TimelineCommentItem.vue";
import NewTimeCommentItem from "/src/components/NewTimelineCommentItem.vue";
import Api from "/src/services/Apis";
import Avatar from "vue-avatar-component";

export default {
  components: {
    Search,
    TimeLineItem,
    TimeCommentItem,
    NewTimeCommentItem,
    Avatar,
  },
  data: () => ({
    showNotification: true,
    show: true,
    selected_timeline: undefined,
    showNewComment: false,
    elementsAvailble: true,
    timelineitems: [],
    timelinecomments: [],
    document: undefined,
    targettedUsersArray: [],
    targettedUsersIds: undefined,
  }),
  methods: {
    getValue(val) {
      console.log(val);
    },
    async getDocument() {
      const { data } = await Api.get(`documents/${this.$route.params.id}`);
      this.document = data;
    },
  
    async getTimeline() {
      const res = await Api.get(
        `document-timelines/document/${this.$route.params.id}`
      );
      this.timelineitems = res.data;
      if (!this.timelineitems.length) this.elementsAvailble = false;
      
    },
    async getTimelineComments() {
      await Api.get(
        `document-timeline-comments/document-timeline/${this.selected_timeline.id}`
      ).then((res) => {
        this.timelinecomments = res.data.content;
      });
    },
      async getCommentTargettedUsers(){
      const response = await Api.get(`users/getTarget/${this.selected_timeline.id}`);
      this.targettedUsersArray = response.data.data;     
    },
    async checkTargetedUser(id){
      this.targettedUsersIds = id;
      console.log("ids", this.targettedUsersIds);
    },
    async AddTimelineComment(content) {
      const res = await Api.create(`document-timeline-comments`, {
        comment: content,
        documentTimelineId: this.selected_timeline.id,
        status: "ACTIVE",
        userId: this.$store.state.auth.user.id,
        targetedUsers: this.targettedUsersIds,
      });
      this.timelinecomments.unshift(res.data.data);
      this.showNewComment = false;
    },
  },
  watch: {
    selected_timeline() {
      this.getTimelineComments();
    },
  },
  async created() {
    await this.getDocument();
    await this.getTimeline();
    if (this.timelineitems.length) {
      this.selected_timeline = this.timelineitems[0];
    }
    await this.getCommentTargettedUsers();
  },
};
</script>

<style lang="scss">
#doc-history {
  // margin: 44px 40px 0px;
  text-align: left;
  .doc-title {
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #032b3d;
    margin-bottom: 45px;
  }
  .infos {
    .item {
      margin-right: 120px;
      place-items: flex-end;
      .label {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        /* identical to box height */
        margin: 0 13px 0 5px;
        color: #032b3d;
      }
      .value {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;

        color: #28a4e2;
      }
    }
  }
  .actions {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    right: 38px;
    &.floating {
      position: absolute;
      top: 118px;
    }
    .update {
      width: 134px;
      height: 35.64px;
      padding-left: 8px;
      background: #1679a8;
      box-shadow: 0px 4px 4px rgba(22, 121, 168, 0.25);
      border-radius: 0px;
      color: #ffffff;
      margin-bottom: 15px;
    }
    .add-comment {
      width: 134px;
      height: 34.56px;
      padding-left: 8px;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(22, 121, 168, 0.25);
      border-radius: 0px;
      color: #1679a8;
    }
    svg {
      margin-right: 11.17px;
    }
  }
  .history {
    margin-top: 17px;
    .summary {
      width: 352px;
      height: 460px;
      padding: 28px 38px;
      background: #ffffff;
      .title {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        /* identical to box height */

        color: #2b2c32;
        svg {
          margin-right: 6px;
        }
        margin-bottom: 30px;
      }
      .timeline {
        margin-top: 45px;
        height: 291px;
        overflow-y: auto;
        overflow-x: hidden;
      }
    }
    .detailed {
      // padding-left: 45px;
      height: 419px;
      overflow-y: auto;
      overflow-x: hidden;

      .image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 6px;
      }
      .date {
        font-family: Manrope;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 3px;
        color: #5c5d63;
      }
      .title {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        margin-bottom: 3px;
        color: #5c5d63;
      }
      .total_comments {
        font-family: Manrope;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;

        color: #1679a8;
      }
      .border-div {
        width: 50px;
        margin-bottom: 5px;
      }
      .messages {
        margin-top: 30px;
      }
    }
  }
  .no-history {
    background: white;
    margin-top: 65px;
    position: absolute;
    width: 83.8%;
    right: 8px;
    display: flex;
    justify-content: center;
    padding: 12px 12px 50px;
    img {
      height: 300px !important;
      width: 376px !important;
    }
    .words {
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 25px;
      line-height: 34px;
      /* identical to box height */

      text-align: center;

      color: #000000;
    }
  }
}

/* Portrait phones and smaller */
@media (max-width: 700px) {
  .no-history {
    width: 96.8% !important;
  }
}
</style>